import React from 'react'
import { Link } from 'gatsby'
import Discord from '../assets/images/social/Discord'
import Facebook from '../assets/images/social/Facebook'
import Twitter from '../assets/images/social/Twitter'
import Instagram from '../assets/images/social/Instagram'
import Twitch from '../assets/images/social/Twitch'
import Youtube from '../assets/images/social/Youtube'

const Postfooter = ({ pageContext }) => {

  return (
    <footer className="footer postfooter postfooter--site">
      <section className="postfooter__legal">
        <div>© 2021 Tales from the Tabletop™ | <Link to="/privacy-policy" className="link">Privacy</Link> | <Link to="/dmca" className="link">DMCA</Link></div>
        <div>Hosting by <a href="https://www.goshortwave.com?ref=tftt" target="_blank" rel="noreferrer" className="link">Shortwave</a></div>
      </section>

      <section className="link-group link-group--social">

        <a href="https://twitter.com/tfttpresents" target="_blank" rel="noreferrer" className="link link--social">
          <Twitter />
        </a>

        <a href="https://discord.gg/bUhUU34d5J" target="_blank" rel="noreferrer" className="link link--social">
          <Discord />
        </a>

        <a href="https://www.twitch.tv/tfttpresents" target="_blank" rel="noreferrer" className="link link--social">
          <Twitch />
        </a>

        <a href="https://www.youtube.com/channel/UCKfmtBBWCs_EDCD_vFJrkXA" target="_blank" rel="noreferrer" className="link link--social">
          <Youtube />
        </a>
      </section>
    </footer>
  )
}

export default Postfooter
