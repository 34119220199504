import React from 'react'
import { Link } from 'gatsby'

import ApplePodcasts from '../assets/images/social/applepodcasts'
import GooglePodcasts from '../assets/images/social/googlepodcasts'
import Spotify from '../assets/images/social/spotify'
import Rss from '../assets/images/social/rss'


const Footer = ({ pageContext }) => {

  return (
    <footer className="footer footer--site" role="navigation">
      <nav className="nav nav--footer">
        <ul>
          <li><b>About</b></li>
          <li><p>Tales from the Tabletop is a group of roleplayers and media producers committed to telling engaging, character-driven stories through tabletop gaming. We share our stories through podcasts, video, streaming, visual art, and written media. <Link to="/about">Learn more</Link>.</p></li>
        </ul>
      </nav>
      <nav className="nav nav--footer">
        <ul>
          <li><b>Information</b></li>
          <li><Link to="/about">About</Link></li>
          <li><Link to="/contact">Contact</Link></li>
          <li><Link to="/schedule">Schedule</Link></li>
          <li><Link to="/about/team">Team</Link></li>

        </ul>
      </nav>
      <nav className="nav nav--footer">
        <ul>
          <li><b>Shows</b></li>
          <li><Link to="/bailehart/edge-of-the-world">Edge of the World</Link></li>
        </ul>
      </nav>
      <nav className="nav nav--footer">
        <ul>
          <li><b>Listen</b></li>
          <li>
            <a href="https://podcasts.apple.com/us/podcast/edge-of-the-world/id1581468972" target="_blank" className="link link--listen" style={{"backgroundColor":"#9933CC"}}>
              <ApplePodcasts />
              <span>Listen on Apple Podcasts</span>
            </a>
          </li>
          <li>
            <a href="https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkLmdvc2hvcnR3YXZlLmNvbS9wb2RjYXN0cy9lZGdlLW9mLXRoZS13b3JsZC15RzJydGpDb3lhZGdhOGdvL3Jzcw" target="_blank" className="link link--listen" style={{"backgroundColor":"#4285F4"}}>
              <GooglePodcasts />
              <span>Listen on Google Podcasts</span>
            </a>
          </li>
          <li>
            <a href="https://open.spotify.com/show/3mFI956nkuHrOrYJGxy4RG" target="_blank" className="link link--listen" style={{"backgroundColor":"#1ED760"}}>
              <Spotify />
              <span>Listen on Spotify</span>
            </a>
          </li>
          <li>
            <a href="https://feed.goshortwave.com/podcasts/edge-of-the-world-yG2rtjCoyadga8go/rss" target="_blank" className="link link--listen" style={{"backgroundColor":"#FFA500"}}>
              <Rss />
              <span>Subscribe to RSS</span>
            </a>
          </li>
        </ul>
      </nav>
    </footer>
  )
}

export default Footer
