import React, {Component} from 'react'
import { X } from 'react-feather'

class Lightbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lightboxStatus: false,
      dispatchedSrc: '',
      dispatchedCaption: ''
    };
  }

  componentDidMount() {
    window.addEventListener('lightboxOpen', this.onLightboxOpen);
  }

  onLightboxOpen = (e) => {
    e.preventDefault();
    console.log(e);
    let stateChange = {};
    stateChange['lightboxStatus'] = true;
    stateChange['dispatchedSrc'] = e.detail.srcset;
    stateChange['dispatchedCaption'] = e.detail.caption;
    this.setState(stateChange);
  }

  triggerLightboxClose = (e) => {
    if(e.target.tagName != "IMG" && e.target.tagName != "A") {
      e.preventDefault();
      let stateChange = {};
      stateChange['lightboxStatus'] = false;
      stateChange['dispatchedSrc'] = '';
      stateChange['dispatchedCaption'] = '';
      this.setState(stateChange);
    }
  }

  render() {
    return (
      <figure onClick={this.triggerLightboxClose} className={`figure-lightbox figure-lightbox--${this.state.lightboxStatus}`} role="aside">
        <X size={36} className="lightbox__close"/>
        {this.state.dispatchedSrc && <img srcSet={this.state.dispatchedSrc} />}
        <figcaption dangerouslySetInnerHTML={{ __html: this.state.dispatchedCaption }} />
      </figure>
    )
  }
}

export default Lightbox
