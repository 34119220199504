import React from 'react'
import Helmet from 'react-helmet'

import Footer from './footer'
import Header from './header'
import Lightbox from './lightbox'
import Postfooter from './postfooter'
import '../style/all.scss'

import favicon from '../assets/images/logos/tfttsquare.svg'

const TemplateWrapper = ({ children }) => (
  <div className="app">
    <Helmet title="Tales from the Tabletop">
      <link rel="icon" href={favicon} />
    </Helmet>
    <Header />
    <main role="main" className="main--site">
      {children}
    </main>
    <Lightbox />
    <Footer />
    <Postfooter />
  </div>
)

export default TemplateWrapper
