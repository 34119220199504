import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'

const MenuMobile = ({ pageContext }) => {

  return (
      <nav className="menu menu--site">
        <ul>
          <li><Link className="link link--menu" to="/tags/video">Video</Link></li>
          <li><Link className="link link--menu" to="/tags/podcast">Podcast</Link></li>
          <li><Link className="link link--menu" to="/schedule">Schedule</Link></li>
          <li><Link className="link link--menu" to="/about">About</Link></li>
          <li><Link className="link link--menu link--featured" to="/listen">Listen</Link></li>
        </ul>
      </nav>
  )
}

export default MenuMobile
