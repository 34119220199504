import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import debounce from 'lodash.debounce'
import LogoWide from '../assets/images/logos/wide.svg'
import LogoTight from '../assets/images/logos/tight.svg'
import MediaQuery from 'react-responsive'
import { Menu, X } from 'react-feather'

import MenuMobile from './menu-mobile'

const Header = ({ pageContext }) => {

  const [isScrolling, setIsScrolling] = useState(false);
  const [isMenu, setIsMenu] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', debounce(listener, 50));
  });

  const listener = (e) => {
    setIsScrolling(window.scrollY > 10);
  };

  return (
    <header className={`header header--site ${isScrolling ? 'header--scroll' : ''}`} role="navigation">
      <nav className="nav nav--site">
        <Link className="link link--home" to="/">
        <MediaQuery maxWidth={959}>
          <img src={LogoTight} />
        </MediaQuery>
        <MediaQuery  minWidth={960}>
          <img src={LogoWide} />
        </MediaQuery>
        </Link>
        <MediaQuery maxWidth={767}>
          <button className="nav__trigger" onClick={() => setIsMenu(!isMenu)}>
            {!isMenu ? <Menu /> : <X />}
          </button>
        </MediaQuery>
        <MediaQuery minWidth={768}>
          <ul className="nav-list nav-list--header">
            <li><Link className="link link--nav" to="/tags/video">Video</Link></li>
            <li><Link className="link link--nav" to="/tags/podcast">Podcast</Link></li>
            <li><Link className="link link--nav" to="/schedule">Schedule</Link></li>
            <li><Link className="link link--nav" to="/about">About</Link></li>
            <li><Link className="link link--nav link--featured" to="/listen">Listen</Link></li>
          </ul>
        </MediaQuery>
      </nav>
      {isMenu && <MediaQuery maxWidth={767}><MenuMobile /></MediaQuery>}
    </header>
  )
}

export default Header
