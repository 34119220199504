import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path d="M1.5 9.68c-.83 0-1.5.67-1.5 1.5v1.63a1.5 1.5 0 103 0v-1.63c0-.83-.67-1.5-1.5-1.5zm21 0c-.83 0-1.5.67-1.5 1.5v1.63a1.5 1.5 0 103 0v-1.63c0-.83-.67-1.5-1.5-1.5zM6.68 14.59c-.83 0-1.5.67-1.5 1.5v1.63a1.5 1.5 0 103 0V16.1c0-.83-.67-1.5-1.5-1.5zm0-9.82c-.83 0-1.5.67-1.5 1.5v5.36a1.5 1.5 0 003 0V6.26c0-.83-.67-1.5-1.5-1.5zm10.64 0c-.83 0-1.5.67-1.5 1.5v1.64a1.5 1.5 0 003 0V6.27c0-.83-.67-1.5-1.5-1.5zM12 0c-.83 0-1.5.67-1.5 1.5v1.63a1.5 1.5 0 103 0V1.5C13.5.67 12.83 0 12 0zm0 19.36c-.83 0-1.5.67-1.5 1.5v1.64a1.5 1.5 0 103 .01v-1.64c0-.82-.67-1.5-1.5-1.5zm5.32-8.46c-.83 0-1.5.68-1.5 1.5v5.33a1.5 1.5 0 003 0V12.4c0-.83-.67-1.5-1.5-1.5zM12 6.13c-.83 0-1.5.68-1.5 1.5v8.73a1.5 1.5 0 003 0V7.64c0-.83-.67-1.5-1.5-1.5z" />
    </svg>
  )
}

export default SvgComponent

